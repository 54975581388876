import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import BorderHorizontalOutlinedIcon from '@material-ui/icons/BorderHorizontalOutlined';
import BorderVerticalOutlinedIcon from '@material-ui/icons/BorderVerticalOutlined';

import { MapContext } from 'context/mapContext/mapContextContainer';
import { JobContext } from 'context/jobContextContainer';
import { driversService } from 'services/driversService';

const DriversSection = ({ driverSectionLayout, setDriverSectionLayout }) => {
  const [driverTableData, setDriverTableData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showLogoffConfirm, setShowLogoffConfirm] = useState(false);
  const [logoffIndex, setLogoffIndex] = useState(-1);
  const { mapData, setMapData } = useContext(MapContext);
  const { setEditJobId, activeDriverList, setActiveDriverList } = useContext(JobContext);
  const offLineLimit = 120;

  useEffect(() => {
    updateTable();
  }, [refreshTable]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateTable();
    }, 1000 * 20);

    return () => clearInterval(interval);
  }, []);

  const updateTable = () => {
    driversService.get_drivers_table().then(({ data }) => {
      if (data.status === 'SUCCESS') {
        const driverList = data.data.data.map(item => {
          let row = { ...item };
          row['name'] = `${item.driver_no} - ${item.fname} ${item.lname}`;
          row['vehicle'] = `${item.vehicle_no} - ${item.vehicle_type}`;

          let status = '';
          let attr = '';

          let date = new Date();
          let timestamp = parseInt(date.getTime() / 1000);

          switch (item['status']) {
            case '1':
              status = 'Idle';
              if (timestamp - item['time'] > offLineLimit) {
                attr = 'cfs';
                status = 'Idle Off-Line';
              }
              break;
            case '2':
            case '3':
              status = 'Hired';
              break;
            default:
              status = '';
              break;
          }

          let offDuty = '';

          switch (item['off_duty']) {
            case 1:
              offDuty = 'Personal';
              break;
            case 2:
              offDuty = 'Relief Time';
              break;
            case 3:
              offDuty = 'Returning Home';
              break;
            case 4:
              offDuty = 'Defective Equipment';
              break;
            case 5:
              offDuty = 'No Charge Fee';
              break;
            case 6:
              offDuty = 'Fare Dispute';
              break;
            default:
              break;
          }

          if (offDuty.length > 0) {
            status = `Off Duty ${offDuty}`;
            attr = 'off_duty';
          }

          row['status'] = status;
          row['attr'] = attr;

          return row;
        });

        setDriverTableData(driverList);
      }
    });
  };

  useEffect(() => {
    if (activeDriverList.length !== driverTableData.length) {
      setActiveDriverList([
        ...driverTableData
      ]);
    }
  }, [driverTableData]);

  const onJobChange = job_id => {
    setEditJobId(job_id);
  };

  const onLogOffClick = () => {
    setShowLogoffConfirm(false);
    driversService.logoff_driver(driverTableData[logoffIndex]['id']).then(({ data }) => {
      if (data.status === 'SUCCESS') {
        setLogoffIndex(-1);
        setRefreshTable(!refreshTable);
      }
    });
  };

  const onLocationDown = rowIndex => {
    if (!driverTableData[rowIndex].lat || !driverTableData[rowIndex].lon) return;

    let tmpContext = Object.assign({}, mapData);
    tmpContext.driver_lat = driverTableData[rowIndex].lat / 1000000.0;
    tmpContext.driver_lng = driverTableData[rowIndex].lon / 1000000.0;
    setMapData(tmpContext);
  };

  const onLocationUp = rowIndex => {
    if (!driverTableData[rowIndex].lat || !driverTableData[rowIndex].lon) return;

    let tmpContext = Object.assign({}, mapData);
    tmpContext.driver_lat = 0;
    tmpContext.driver_lng = 0;
    setMapData(tmpContext);
  };

  return (
    <DriversSectionWrapper className="main--driver-section-wrapper">
      <Paper className="main--driver-section" elevation={3} square>
        <div className="main--driver-body">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Driver</TableCell>
                  <TableCell align="center">Vehicle</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Jobs</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {driverTableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.vehicle}</TableCell>
                    <TableCell align="center">
                      {' '}
                      <Chip className={'chip-' + row.status} label={row.status} />
                    </TableCell>
                    <TableCell align="center">
                      <Select
                        variant="outlined"
                        value={0}
                        onChange={e => onJobChange(e.target.value)}
                      >
                        <MenuItem value={0}>{`Jobs (${row.jobs.length})`}</MenuItem>
                        {row.jobs &&
                          row.jobs.map((item, index1) => {
                            return (
                              <MenuItem value={item} key={index1}>
                                {item}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </TableCell>
                    <TableCell align="center">
                      <ExitToAppOutlinedIcon
                        onClick={() => {
                          setLogoffIndex(index);
                          setShowLogoffConfirm(true);
                        }}
                        style={{ fontSize: 25, marginRight: 5, cursor: 'pointer' }}
                      />
                      <RoomOutlinedIcon
                        onMouseDown={() => onLocationDown(index)}
                        onMouseUp={() => onLocationUp(index)}
                        style={{ fontSize: 25, marginRight: 20, cursor: 'pointer' }}
                        {...(row.lat && row.lon ? {} : { color: 'disabled' })}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="main--driver-layout-button">
          <IconButton
            aria-label="delete"
            onClick={() => {
              setDriverSectionLayout(driverSectionLayout === 0 ? 1 : 0);
            }}
          >
            {driverSectionLayout === 0 ? (
              <BorderHorizontalOutlinedIcon />
            ) : (
              <BorderVerticalOutlinedIcon />
            )}
          </IconButton>
        </div>
      </Paper>

      <Dialog
        open={showLogoffConfirm}
        onClose={() => setShowLogoffConfirm(false)}
      >
        <DialogTitle id="alert-dialog-title">{'Driver Logoff'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {logoffIndex >= 0
              ? `Do you really want to log off driver(#${driverTableData[logoffIndex]['driver_no']})?`
              : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onLogOffClick} color="primary">
            Yes
          </Button>
          <Button onClick={() => setShowLogoffConfirm(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </DriversSectionWrapper>
  );
};

export default DriversSection;

const DriversSectionWrapper = styled.div`
  .MuiChip-root {
    height: 25px;
    color: white;

    &.chip-Idle {
      background: var(--orange-main-color);
    }

    &.chip-Working {
      background: var(--green-main-color);
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  .MuiOutlinedInput-input {
    padding: 5px 10px;
    font-size: 14px;
    height: auto;
    width: 100px;
  }

  .MuiSvgIcon-root {
    color: var(--blue-dark-color);
  }

  .MuiSvgIcon-colorDisabled {
    color: rgba(0, 0, 0, 0.26);
  }
`;
