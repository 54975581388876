import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn, isDisabledLoggedIn } from 'services/loginService';
import MainLayout from 'layouts/mainLayout';
import IndexPage from 'components/Pages/index';
import MapContextContainer from 'context/mapContext/mapContextContainer';
import JobContextContainer from 'context/jobContextContainer';
import CallContextContainer from 'context/callContextContainer';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import NotificationOverlay from 'components/NotificationOverlay';

const MainTemplate = props => {
  if (!isLoggedIn() && typeof window !== 'undefined') {
    navigate('/login');
    return null;
  }

  if (isDisabledLoggedIn() && typeof window !== 'undefined') {
    navigate('/manage/settings/stripe/bills/');
    return null;
  }

  return (
    <MainLayout>
      <CircularProgressOverlay>
        <NotificationOverlay>
          <CallContextContainer>
            <MapContextContainer>
              <JobContextContainer>
                {isLoggedIn() && <IndexPage {...props} />}
              </JobContextContainer>
            </MapContextContainer>
          </CallContextContainer>
        </NotificationOverlay>
      </CircularProgressOverlay>
    </MainLayout>
  );
};

export default MainTemplate;
