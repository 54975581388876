/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';

import { JobContext } from 'context/jobContextContainer';

import { siteSetting } from 'services/loginService';
import { driversService } from 'services/driversService';
import { vehiclesService } from 'services/vehiclesService';

import SEO from '../seo';
import CallSection from '../CallSection/CallSection';
import JobSection from '../JobSection/JobSection';
import MapSection from '../MapSection/MapSection';
import DriversSection from '../DriversSection/DriversSection';
import JobTableSection from '../JobTableSection/JobTableSection';
import ZohoChat from '../ZohoSupport';
import { getLoginUser } from '../../services/loginService';

const IndexPage = () => {
  const [driverSectionLayout, setDriverSectionLayout] = React.useState(1);
  const [mapType, setMapType] = useState(siteSetting().setting?.maps_provider || 'MAPBOX'); //MAPBOX, GOOGLE
  const [jobTableRefresh, setJobTableRefresh] = useState(false);
  const [reservationTableRefresh, setReservationTableRefresh] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setDriverList } = useContext(JobContext);

  useEffect(() => {
    async function checkDriverEmpty() {
      const { data } = await driversService.admin_get_drivers_table({}, '',  1, 10);
      if (data.status === 'SUCCESS') {
        if (parseInt(data?.data?.total || 0) <= 0) {
          enqueueSnackbar('You haven\'t added any driver yet. Please add drivers.', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            TransitionComponent: Slide,
            persist: true,
            action: (
              <>
                <Button color="primary" size="small" onClick={() => navigate('/manage/drivers/')}>
                  Add Driver
                </Button>
              </>
            )
          });
        }
      }
    }

    async function checkVehicleEmpty() {
      const { data } = await vehiclesService.admin_get_vehicles_table({}, '',  1, 10);
      if (data.status === 'SUCCESS') {
        if (parseInt(data?.data?.total || 0) <= 0) {
          enqueueSnackbar('You haven\'t added any vehicle yet. Please add vehicles.', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            TransitionComponent: Slide,
            persist: true,
            action: (
              <>
                <Button color="primary" size="small" onClick={() => navigate('/manage/vehicles/')}>
                  Add Vehicle
                </Button>
              </>
            )
          });
        }
      }
    }

    async function getDriversList() {
      const data = await driversService.get_drivers({});
      if (data && data.data.status === 'SUCCESS' && data.data.data.num_rows) {
        const drivers = data.data.data.data?.map(item => ({
          id: parseInt(item.driver_id),
          label: `${item.driver_no} - ${item?.fname?.length + item?.lname?.length === 0 ? '(No Name)' : `${item?.fname} ${item?.lname}`}`
        }))
        setDriverList(drivers);
      }
    }

    checkDriverEmpty();
    checkVehicleEmpty();
    getDriversList();
  }, []);


  return (
    <>
      <SEO title="Home" />
      <ZohoChat userEmail={getLoginUser().email} userName={getLoginUser().dbname} />

      <MainWrapper className="main-wrapper" id="main-wrapper">
        <div className="main-container">
          <CallSection />

          <div className="main--job-map-section">
            <JobSection
              mapType={mapType}
              jobTableRefresh={() => setJobTableRefresh(!jobTableRefresh)}
            />

            <div className={`main--map-section-wrapper ${driverSectionLayout === 0 ? 'horizontal-layout' : 'vertical-layout'}`}>
              <MapSection mapType={mapType} />
              <DriversSection
                driverSectionLayout={driverSectionLayout}
                setDriverSectionLayout={setDriverSectionLayout}
              />
            </div>
          </div>

          <JobTableSection
            jobTableRefresh={jobTableRefresh}
            reservationTableRefresh={reservationTableRefresh}
          />
        </div>
      </MainWrapper>
    </>
  );
};

export default IndexPage;

const MainWrapper = styled.div`
  .main-container {
    padding: 10px;

    .main--job-map-section {
      margin-top: 10px;
      display: flex;

      .main--map-section-wrapper {
        flex: auto;
        display: flex;

        .main--driver-section {
          flex: 1;
          max-width: 400px;
          border: 2px solid var(--blue-dark-color);
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          position: relative;

          .main--driver-title {
            background: var(--blue-dark-color);
            font-size: 16px;
            padding: 15px 10px;
          }

          .main--driver-body {
            height: 100%;
            position: relative;

            .MuiTableContainer-root {
              height: 100%;
              border-radius: 0;
              background: #0b1a2111;
            }

            .MuiTable-root {
              .MuiTableHead-root {
                background: var(--blue-dark-color);
              }

              .MuiTableCell-root {
                padding: 5px 10px;
                font-size: 13px;
                border-bottom: 1px solid #aaa;
              }

              .MuiTableCell-head {
                font-size: 14px;
                line-height: 25px;
                padding: 11px 5px;
                color: white;
              }
            }
          }

          .main--driver-layout-button {
            position: absolute;
            right: 5px;
            bottom: 5px;
            opacity: 0.5;
            text-align: right;

            .MuiIconButton-root {
              background: #ddd;

              &:hover {
                background: #ddf;
              }
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        &.vertical-layout {
          flex-direction: column;

          .main--map-section {
            margin-right: 0;
          }

          .main--driver-section-wrapper {
            padding-top: 10px;
          }

          .main--driver-section {
            max-width: 100%;
            max-height: 160px;
            overflow: auto;
          }
        }
      }
    }
  }

  @media (max-width: 1299px) {
    .main-container {
      .main--job-map-section {
        .main--map-section-wrapper {
          flex-direction: column;

          .main--driver-section {
            .main--driver-layout-button {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 830px) {
    .main-container {
      .main--job-map-section {
        flex-direction: column;
      }
    }
  }
`;
