import React, { useEffect } from 'react';

const ZohoChat = ({ userEmail, userName }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Initialize Zoho SalesIQ object
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || { ready: function() {} };

      // Create and insert the Zoho SalesIQ script
      const script = document.createElement('script');
      script.id = 'zsiqscript';
      script.src = 'https://salesiq.zohopublic.com/widget?wc=siq5b48cab4d507c38361b415ee5c3b912f4cb809e96fd59e095d8b531a49203aec';
      script.defer = true;
      document.body.appendChild(script);

      // Polling mechanism to check if the script is ready
      const checkZohoReady = () => {
        if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.visitor) {
          window.$zoho.salesiq.visitor.name(userName);
          window.$zoho.salesiq.visitor.email(userEmail);
        } else {
          setTimeout(checkZohoReady, 300); // Retry every 300ms until ready
        }
      };

      script.onload = () => {
        checkZohoReady();
      };

      // Clean up the script on component unmount
      return () => {
       
      };
    }
  }, [userEmail, userName]);

  return null;
};

export default ZohoChat;
